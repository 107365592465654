export const environment = {
  production: true,
  name: 'beta',
  enableServiceWorker: true,
  server_url: 'https://test.li-x.com/api',
  pdfUrl: 'https://test.li-x.com/api',
  storageUrl: 'https://storage.li-x.com',
  origin: 'https://test.li-x.com',
  environment: 'test',
  // originKey:
  //   'pub.v2.8215746840911625.aHR0cHM6Ly90ZXN0LmxpLXguY29t.0HmXl6aZ2HfKSXI9GzepoytVjGKdEEPuH1grissXKkU',
  cookieURL: 'test.li-x.com',
  gaID: 'G-XVQZHTS4ZK',
  telemetrySiteId: '2',
}
